<template>
  <div id="home-page">
    <top-nav-new />
    <!-- 首页banner -->
    <div class="pc-banner d-none d-lg-block" style="position: relative">
      <div class="lunbo d-none d-lg-block" style="width: 100%; height: 640px;">
        <swiper class="Mswiper" :options="swiperOptionOffice">
          <swiper-slide>
            <img src="@/assets/newSite/banner/b1.png" alt="" style="height:640px;width:100%;" />
            <div
              style="
                color: white;
                position: absolute;
                left: 240px;
                top: 170px;
                font-weight: 100;
                text-align: left;
              "
            >
              <div style="font-size: 42px; margin-bottom: 30px;font-weight:700;">
                一站式工业数字化转型解决方案
              </div>
              <div style="font-size: 18px; width: 700px;line-height:2rem;">
                优制云工业互联网平台是专为中小微企业量身打造的一站式数字化转型解决方案。该平台以智能制造为引擎，深度融合数智办公与智慧园区的先进理念，精准覆盖并优化企业九大核心业务场景。凭借9大核心产品及超过500个灵活配置模块，构建起一个高效、智能且可持续发展的工业互联网生态系统。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/banner/b2.png" alt="" style="height:640px;width:100%;" />
            <div
              style="
                color: white;
                position: absolute;
                left: 240px;
                top: 170px;
                font-weight: 100;
                text-align: left;
                padding-top:70px;
              "
            >
              <div style="font-size: 42px; margin-bottom: 30px;font-weight:700;">
                智能，赋能未来
              </div>
              <div style="font-size: 18px; width: 700px">
                深圳优制云工业互联网公司，专为中小微企业提供基于伟班平台的智能制造、数字企业及智慧园区一站式数字化解决方案与服务，助力企业高效转型，迈向智能化未来。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/banner/b3.png" alt="" style="height:640px;width:100%;" />
            <div
              style="
                color: white;
                position: absolute;
                left: 240px;
                top: 170px;
                font-weight: 100;
                text-align: left;
                padding-top:70px;
              "
            >
              <div style="font-size: 42px; margin-bottom: 30px;font-weight:700;">
                开启您的免费试用计划
              </div>
              <div style="font-size: 18px; width: 700px">
                <el-button round type="primary" @click="handleTry">免费试用</el-button>
              </div>
            </div>
          </swiper-slide>
          <div
            class="swiper-pagination-top-off"
            style="text-align: left; color: white"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>

    <div class="mobile-banner d-block d-lg-none" style="position: relative;">
      <swiper class="MswiperMobile" :options="swiperOptionBanner">
        <swiper-slide>
          <div style="text-align: left">
            <img
              src="@/assets/newSite/banner/b1.png"
              alt=""
              style="width: 100%;height: 280px;"
            />
            <div
              style="
                color: white;
                position: absolute;
                left: 40px;
                top: 40px;
                font-weight: 100;
                text-align: left;
              "
            >
              <div style="font-size: 30px;;margin-bottom: 10px;font-weight:200;">
                一站式工业数字化<br>转型解决方案
              </div>
              <div style="font-size: 14px; width: 700px">
                伟班平台是深圳优制云工业互联网公司专为中小微<br>企业量身打造的一站式数字化转型解决方案。
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div style="text-align: left">
            <img
              src="@/assets/newSite/banner/b2.png"
              alt=""
              style="width: 100%;height: 280px;"
            />
            <div
              style="
                color: white;
                position: absolute;
                left: 40px;
                top: 40px;
                font-weight: 100;
                text-align: left;
              "
            >
              <div style="font-size: 30px;;margin-bottom: 10px">
                优制云智能制造<br>MES系统
              </div>
              <div style="font-size: 14px; width: 700px">
                优制云工业互联网平台智能制造MES产品，直击中<br>小微企业面临的智能制造痛点。传统生产管理往往<br>面临生产计划不透明、生产进度难以实时掌握、质<br>量控制依赖人工且效率低下等难题。
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div style="text-align: left">
            <img
              src="@/assets/newSite/banner/b3.png"
              alt=""
              style="width: 100%;height: 280px;"
            />
            <div
              style="
                color: white;
                position: absolute;
                left: 40px;
                top: 40px;
                font-weight: 100;
                text-align: left;
              "
            >
            <div style="font-size: 30px;;margin-bottom: 10px">
                一站式工业数字化<br>转型解决方案
              </div>
              <div style="font-size: 14px; width: 700px">
                伟班平台是深圳优制云工业互联网公司专为中小微<br>企业量身打造的一站式数字化转型解决方案。
              </div>
            </div>
          </div>
        </swiper-slide>
        <div
          class="swiper-pagination-w"
          style="text-align: left"
          slot="pagination"
        ></div>
      </swiper>

    </div>

    <!-- 实力见证，引领未来 -->
    <section class="pc-future-provide d-none d-lg-block">
      <div class="container text-center">
        <h1 class="pro-tit">
          实力见证，赋能未来
        </h1>
        <!-- <h5 class="pro-tits">软件定义制造，智能引领未来</h5> -->
        <div class="row" style="box-shadow:0px 0px 40px rgba(0, 0, 0, 0.1);border-radius:20px;">
          <div class="col">
            <div class="items">
              <h2><img src="@/assets/newSite/home/f1.png" alt="" style="width: 100px;height:100px;" /></h2>
              <div style="margin-bottom: 1rem; text-align: left">
                <h1 class="datas">
                  <countTo
                    :startVal="startVal"
                    :endVal="endVal"
                    :duration="9"
                  ></countTo
                  >+
                </h1>
                <h4 class="tip">覆盖工业领域9+业务场景应用</h4>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="items">
              <div><img src="@/assets/newSite/home/f2.png" alt="" style="width: 100px;height:100px;"  /></div>
              <div style="margin-bottom: 1rem; text-align: left">
                <h1 class="datas">
                  <countTo
                    :startVal="startCompanys"
                    :endVal="endCompanys"
                    separator=""
                    :duration="4000"
                  ></countTo
                  >+
                </h1>
                <h4 class="tip">累积获得40+项专利和软著</h4>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="items">
              <div><img src="@/assets/newSite/home/f3.png" alt="" style="width: 100px;height:100px;" /></div>
              <div style="margin-bottom: 1rem; text-align: left">
                <h1 class="datas">
                  <countTo
                    :startVal="startUser"
                    :endVal="endUser"
                    separator=""
                    :duration="4000"
                  ></countTo
                  >+
                </h1>
                <h4 class="tip">软件应用领域覆盖100+细分行业</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-future-provide d-block d-lg-none">
      <div class="container text-center">
        <h3 class="pro-tit" style="margin-bottom:15px;">
          实力见证，赋能未来
        </h3>
        <h6 class="pro-des">致力于为用户提供全行业解决方案</h6>
        <div class="row" style=";padding-top:15px;">
          <div class="">
            <div class="items">
              <h2><img src="@/assets/newSite/home/f1.png" alt="" style="width: 100px;height:100px;" /></h2>
              <div style="margin-bottom: 1rem; text-align: left">
                <h2 class="datas">
                  <countTo
                    :startVal="startVal"
                    :endVal="endVal"
                    :duration="9"
                  ></countTo
                  >+
                </h2>
                <h4 class="tip">覆盖工业领域9+业务场景应用</h4>
              </div>
            </div>
          </div>
          <div class="">
            <div class="items">
              <div><img src="@/assets/newSite/home/f2.png" alt="" style="width: 100px;height:100px;"  /></div>
              <div style="margin-bottom: 1rem; text-align: left">
                <h2 class="datas">
                  <countTo
                    :startVal="startCompanys"
                    :endVal="endCompanys"
                    separator=""
                    :duration="4000"
                  ></countTo
                  >+
                </h2>
                <h4 class="tip">累积获得40+项专利和软著</h4>
              </div>
            </div>
          </div>
          <div class="">
            <div class="items">
              <div><img src="@/assets/newSite/home/f3.png" alt="" style="width: 100px;height:100px;" /></div>
              <div style="margin-bottom: 1rem; text-align: left">
                <h2 class="datas">
                  <countTo
                    :startVal="startUser"
                    :endVal="endUser"
                    separator=""
                    :duration="4000"
                  ></countTo
                  >+
                </h2>
                <h4 class="tip">软件应用领域覆盖100+细分行业</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-soft-provide d-block d-lg-none" v-if="false">
      <div class="container text-center">
        <h3 class="pro-tit">工业互联网领域软件提供商</h3>
        <h5 class="pro-tits">软件定义制造，智能引领未来</h5>
        <div class="wrap">
          <div class="row">
            <div class="col">
              <div class="items">
                <div>
                  <img
                    src="@/assets/newSite/a.png"
                    class="avatar avatar-lg mr-3"
                  />
                </div>
                <div style="text-align: left">
                  <h2 class="datas">
                    <countTo
                      :startVal="startVal"
                      :endVal="endVal"
                      :duration="3000"
                    ></countTo
                    >年
                  </h2>
                  <h5 class="tip">专注工业互联网软件领域</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="items">
                <div><img src="@/assets/newSite/b.png" alt="" /></div>
                <div style="text-align: left">
                  <h2 class="datas">
                    <countTo
                      :startVal="startCompanys"
                      :endVal="endCompanys"
                      separator=""
                      :duration="3000"
                    ></countTo
                    >+
                  </h2>
                  <h5 class="tip">致力于服务中小微企业</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="items">
                <div><img src="@/assets/newSite/c.png" alt="" /></div>
                <div style="text-align: left">
                  <h2 class="datas">
                    <countTo
                      :startVal="startUser"
                      :endVal="endUser"
                      separator=""
                      :duration="3000"
                    ></countTo
                    >+
                  </h2>
                  <h5 class="tip">致力于产品用户数量</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 企业生产经营管理全链路数字化 -->
    <section class="pc-number d-none d-lg-block" style="margin-top: 80px">
      <div class="pro-wrap">
        <div
          class="container text-center"
          style="
            justify-content: center;
            flex-direction: column;
            padding-top: 50px;
          "
        >
          <h1 class="pro-tit" style="margin-bottom: 20px">
            企业生产经营管理全链路数字化
          </h1>
          <h6>
            优制云工业互联网平台，全面覆盖企业生产经营管理九大业务场景，为中小微企业构建一站式数字化转型解决方案。
          </h6>
        </div>
        <div class="container animate__animated animate__fadeInLeftBig">
          <div class="content cot">
            <div class="mes">
              <div style="font-size: 20px; color: #3384ff">智能制造MES</div>
              <div>生产追溯、设备管理、质量管理、数据采集、安灯预警等</div>
            </div>
            <div class="hrm" style="text-align: right">
              <div style="font-size: 20px; color: #3384ff">人力资源管理HRM</div>
              <div>招聘管理、目标绩效、薪酬管理、组织管理、培训管理等</div>
            </div>
            <div class="isc">
              <div style="font-size: 20px; color: #3384ff">集成供应链ISC</div>
              <div>销售管理、采购管理、生产管理、库存管理等</div>
            </div>
            <div class="FIMS" style="text-align: right">
              <div style="font-size: 20px; color: #3384ff">财务管理FIMS</div>
              <div>费用管理、资金管理、应收应付等</div>
            </div>
            <div class="erp">
              <div style="font-size: 20px; color: #3384ff">企业资源计划ERP</div>
              <div>销售、采购、生产、库存、财务、报表</div>
            </div>
            <div class="plm" style="text-align: right">
              <div style="font-size: 20px; color: #3384ff">研发项目管理PLM</div>
              <div>项目管理（PMS）、协同管理、研发集成等</div>
            </div>

            <div class="wms">
              <div style="font-size: 20px; color: #3384ff">仓储管理系统WMS</div>
              <div>收货管理、条码管理、库存管理、盘点、报表</div>
            </div>
            <div class="crm" style="text-align: right">
              <div style="font-size: 20px; color: #3384ff">客户关系管理CRM</div>
              <div>客户管理、销售管理、数据报表等</div>
            </div>

            <div class="ibms" style="text-align: center">
              <div style="font-size: 20px; color: #3384ff">
                智慧园区管理IBMS
              </div>
              <div>智慧运营、智慧安防、智慧设备等</div>
            </div>
          </div>
        </div>
      </div>
    </section>

     <section class="mobile-number d-block d-lg-none" style="margin-top: 40px">
      <div class="pro-wrap">
        <div
          class="container text-center"
          style="
            justify-content: center;
            flex-direction: column;
            padding-top: 50px;
          "
        >

          <div class="main">
            <h3 class="pro-tit" style="margin-bottom: 20px">
            企业生产经营管理全链路数字化
          </h3>
            <div class="des">
              伟班平台，全面覆盖企业生产经营管理九大
            </div>
            <div class="des">
              业务场景，为中小微企业构建一站式工业互
            </div>
            <div class="des">
              联网数字化转型解决方案。
            </div>            
          </div>

        </div>
        <div class="container animate__animated animate__fadeInLeftBig">
          <div class="content cot"></div>
        </div>
      </div>
    </section>

    <!-- 应用场景 PC -->
    <div class="pc-lunbo d-none d-lg-block " style="width: 100%;margin-top: 80px;">
        <h1 class="pro-tit">应用场景</h1>
        <h6 style="margin: 20px 0 40px 0;">全行业、全场景，高效应用</h6>
        <swiper class="Mswiper" :options="swiperOptionSence">
          <swiper-slide>
            <img src="@/assets/newSite/home/h1.png" alt="" width="100%"  />
             <div class="wrap">
                 <div class="tit">塑料管道</div>
                 <div class="pro">自动化、可视化生产管理，实现智能制造</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h2.png" alt="" width="100%"/>
            <div class="wrap">
                 <div class="tit">汽车制造</div>
                 <div class="pro">优化生产流程，缩短车辆上市周期</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h3.png" alt="" width="100%" />
            <div class="wrap">
                 <div class="tit">电子电器</div>
                 <div class="pro">数据驱动生产管理，优化库存和供应链</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h4.png" alt="" width="100%" />
            <div class="wrap">
                 <div class="tit">服装纺织</div>
                 <div class="pro">订单管理和物料采购数字化，精准市场定位</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h5.png" alt="" width="100%"/>
            <div class="wrap">
                 <div class="tit">食品加工</div>
                 <div class="pro">生产过程自动检测，确保食品安全和质量稳定</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h6.png" alt=""  width="100%" />
            <div class="wrap">
                 <div class="tit">玩具制造</div>
                 <div class="pro">智能管理生产线，提高生产效率和产品质量</div>
             </div>
          </swiper-slide>
        </swiper>
    </div>

    <div class="mobile-lunbo d-block d-lg-none " style="width: 100%;margin-top: 110px;">
        <h5 class="pro-tit">应用场景</h5>
        <h6 style="margin: 20px 0 40px 0;">全行业、全场景，高效应用</h6>
        <swiper class="Mswiper" :options="swiperOptionSenceMobile">
          <swiper-slide>
            <img src="@/assets/newSite/home/h1.png" alt="" width="460px" height="336px" />
             <div class="wrap">
                 <div class="tit">塑料管道</div>
                 <div class="pro">自动化、可视化生产管理，实现智能制造</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h2.png" alt="" width="460px" height="336px"/>
            <div class="wrap">
                 <div class="tit">汽车制造</div>
                 <div class="pro">优化生产流程，缩短车辆上市周期</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h3.png" alt="" width="460px" height="336px"/>
            <div class="wrap">
                 <div class="tit">电子电器</div>
                 <div class="pro">数据驱动生产管理，优化库存和供应链</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h4.png" alt="" width="460px" height="336px"/>
            <div class="wrap">
                 <div class="tit">服装纺织</div>
                 <div class="pro">订单管理和物料采购数字化，精准市场定位</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h5.png" alt="" width="460px" height="336px"/>
            <div class="wrap">
                 <div class="tit">食品加工</div>
                 <div class="pro">生产过程自动检测，确保食品安全和质量稳定</div>
             </div>
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/newSite/home/h6.png" alt=""  width="460px" height="336px"/>
            <div class="wrap">
                 <div class="tit">玩具制造</div>
                 <div class="pro">智能管理生产线，提高生产效率和产品质量</div>
             </div>
          </swiper-slide>
        </swiper>
    </div>

  <!-- 成功案列 -->
   <section class="pc-product d-none d-lg-block" style="margin:80px 0 50px 0;">
      <div class="container text-center">
        <h1 class="pro-tit" style="margin-bottom:20px;">成功案例</h1>
        <div class="row align-items-start">
          <div
            :class="['col', idx == index ? 'acticv' : '']"
            @click="handleSwitch(index)"
            v-for="(item, index) in tabs"
            :key="index"
          >
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="pro-wrap">
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 0"
        >
          <div class="content" style="display: flex;padding:20px 0 ;">
            <div class="wrap">
              <h6 style="font-size:35px;">{{caseA.contentTitle}}</h6>
              <p class="p-content">
                {{caseA.contentDescription}}
              </p>
              <div>
                <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 1"
        >
          <div class="content" style="display: flex;padding:20px 0 ;">
            <div class="wrap" style="flex: 60%">
              <h6 style="font-size:35px;">{{caseB.contentTitle}}</h6>
              <p class="p-content">
                {{caseB.contentDescription}}
              </p>
              <div>
                <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInRightBig"
          v-show="idx == 2"
        >
          <div class="content" style="display: flex;padding:20px 0 ;">
            <div class="wrap" style="flex: 60%">
              <h6 style="font-size:35px;">{{caseC.contentTitle}}</h6>
              <p class="p-content">
                {{caseC.contentDescription}}
              </p>
              <div>
                <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
            </div>
          </div>
        </div>
      </div>
    </section>  

    <section class="mobile-product d-block d-lg-none" style="margin-top:40px;">
      <div class="container text-center">
        <h3 class="pro-tit">成功案例</h3>
        <div class="row align-items-start">
          <div
            :class="['col', idx == index ? 'acticv' : '']"
            @click="handleSwitch(index)"
            v-for="(item, index) in tabs"
            :key="index"
          >
          <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="pro-wrap">
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 0"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6 style="font-weight: 700; margin-bottom: 1rem">优制云助力华瀚管道实现智能化生产</h6>
              <p class="p-content">
                优制云助力华瀚管道实现智能化生产，通过智能制造MES系统的深度应用，实现了生产流程的精细化管理和智能化控制。系统优化了生产计划、实时监控设备状态、自动采集生产数据，有效提升了生产透明度，为华瀚管道带来了显著的经济效益和市场竞争优势。
              </p>

            </div>
            <div style=" text-align: center;margin-bottom:20px;">
              <img src="@/assets/newSite/home/cases.png" />
            </div>
            <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow('/smartMade');
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </el-button>
              </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 1"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6 style="font-weight: bold; margin-bottom: 0.5rem">智能办公</h6>
              <p class="p-content">
                智能化：借助先进的技术和设备，智能办公能够实现智能化决策、智能化处理、智能化控制等，提高工作效率和质量。
                高效性：能够减少繁琐的手动操作，简化工作流程，提高工作效率。
                协同性：能够实现团队协作的高效协同，提高团队协作效率。
                安全性：具备较高的安全性，能够保护企业和员工的隐私和数据安全。
                移动性：支持移动办公，IM即时聊天，员工可以随时随地进行办公，提高工作效率和灵活性。
                云端化：基于云计算技术实现，能够实现数据的云端存储、备份和恢复等功能，提高数据的安全性和可靠性。
                数据分析：能够对各种数据进行分析和挖掘，为企业提供更全面的数据支持和决策参考。
              </p>
              <div class="btns" style="">
                <el-tag type="info" style="">伟班（OA）</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.openNewWindow('/smartOffice');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s2.png" />
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInRightBig"
          v-show="idx == 2"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6 style="font-weight: bold; margin-bottom: 0.5rem">智慧园区</h6>
              <p class="p-content">
                数据可视化及应用：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                能耗管理：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限…
                安全管理：视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率…
                设备运维：融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患…
                服务体验：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                集成能力：打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app…
              </p>
              <div class="btns">
                <el-tag type="info" style="">智慧园区</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.openNewWindow('/smartPark');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  立即体验
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s3.png" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 权威认证 -->
    <section class="pc-quanwei d-none d-lg-block">
      <div class="container text-center" style="padding-bottom:80px;">
        <h1 class="pro-tit">权威认证</h1>

        <div class="cot">
           <div class="items">
            <img src="@/assets/newSite/home/q1.png" />
            <div>高新技术企业</div>
           </div>
           <div class="items">
            <img src="@/assets/newSite/home/q2.png" />
            <div>软件企业</div>
           </div>
           <div class="items">
            <img src="@/assets/newSite/home/q3.png" />
            <div>创新型中小企业</div>
           </div>
           <div class="items">
            <img src="@/assets/newSite/home/q4.png" />
            <div>ISO 9001</div>
           </div>
      </div>

      </div>

    </section>  
    <!-- 权威认证 -->
    <section class="mobile-quanwei d-block d-lg-none">
      <div class="container text-center" style="padding-bottom:0;">
        <h1 class="pro-tit">权威认证</h1>
        <div class="cot">
           <div class="items">
            <img src="@/assets/newSite/home/q1.png" />
            <div>高新技术企业</div>
           </div>
           <div class="items">
            <img src="@/assets/newSite/home/q2.png" />
            <div>软件企业</div>
           </div>
           <div class="items">
            <img src="@/assets/newSite/home/q3.png" />
            <div>创新型中小企业</div>
           </div>
           <div class="items">
            <img src="@/assets/newSite/home/q4.png" />
            <div>ISO 9001</div>
           </div>
      </div>

      </div>

    </section>  

    <!-- 合作伙伴 -->
   <section class="pc-parkner d-none d-lg-block">
      <div class="container text-center">
        <h1 class="pro-tit">合作伙伴</h1>
        <div class="pro-wrap">
             <div class="item">
              <img src="@/assets/newSite/home/pt1.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt2.png" />
             </div>
             <!-- <div class="item">
              <img src="@/assets/newSite/home/pt3.png" />
             </div> -->
             <div class="item">
              <img src="@/assets/newSite/home/pt4.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt7.png" />
             </div>
             <!-- <div class="item">
              <img src="@/assets/newSite/home/pt9.png" />
             </div> -->

             <!-- <div class="item">
              <img src="@/assets/newSite/home/pt6.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt7.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt8.png" />
             </div>

             <div class="item">
              <img src="@/assets/newSite/home/pt10.png" />
             </div> -->

 
      </div>
      </div>

    </section>  
    <section class="mobile-parkner d-block d-lg-none">
      <div class="container text-center">
        <h1 class="pro-tit">合作伙伴</h1>
        <div class="pro-wrap">
             <div class="item">
              <img src="@/assets/newSite/home/pt1.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt2.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt3.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt4.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt5.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt6.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt7.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt8.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt9.png" />
             </div>
             <div class="item">
              <img src="@/assets/newSite/home/pt10.png" />
             </div>

 
      </div>
      </div>

    </section>

    <section class="pc-resolve d-none d-lg-block" v-if="false">
      <div class="container text-center">
        <h3 class="pro-tit">解决方案</h3>
        <h5 class="pro-tits">致力于为用户提供全行业解决方案</h5>
        <div class="row bg1 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h4>智能办公解决方案</h4>
            <p>
              致力于为广大中小微企业提供综合的、标准的、一体化的、全方位的数字化解决方案
              以“互联、共享、智能”理念为核心,助力企业数字化转型,成就智慧企业。
            </p>
            <button
              @click="
                () => {
                  this.openNewWindow('/officeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg2 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h4>智能制造解决方案</h4>
            <p>
              帮助企业实现智能化、高效化的生产管理，提高工作效率和业务水平，同时也可以降低成本、减少风险，为企业的发展提供有力支持。
            </p>
            <button
              @click="
                () => {
                  this.openNewWindow('/madeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg3 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h4>智慧园区解决方案</h4>
            <p>
              数智赋能让园区更智能、更便捷、更高效、面向园区运营方、管理机构、企业及个人提供全栈式数智服务，融合物联网、大数据、AI、数字孪生等领域的产品能力，打造园区全联接、全融合、全智能，助力园区及企业实现基础设施智能化、运营管理数字化、产业发展生态化的智慧化目标。
            </p>
            <button
              @click="
                () => {
                  this.openNewWindow('/parkSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-resolve d-block d-lg-none" v-if="false">
      <div class="container text-center">
        <h3 class="pro-tit">解决方案</h3>
        <h5 class="pro-tits">致力于为用户提供全行业解决方案</h5>
        <div class="row bg1 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h6>智能办公解决方案</h6>
            <!-- <h6>解决方案</h6> -->
            <button
              @click="
                () => {
                  this.openNewWindow('/officeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg2 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h6>智能制造解决方案</h6>
            <button
              @click="
                () => {
                  this.openNewWindow('/madeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg3 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h6>智慧园区解决方案</h6>
            <button
              @click="
                () => {
                  this.openNewWindow('/parkSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="pc-news d-none d-lg-block" v-if="false">
      <div class="container text-center">
        <h3 class="pro-tit">新闻资讯</h3>
        <div class="news-swiper">
          <div class="controls">
            <div>
              <div
                @click="handleSelectTpye(idxs)"
                v-for="(itm, idxs) in types"
                :key="idxs"
                :class="['opertaion', sel == idxs ? 'active' : '']"
              >
                <span>{{ itm }}</span>
                <span
                  style="margin-left: 10px"
                  class="el-icon-arrow-right"
                ></span>
              </div>
            </div>
            <div class="preAndNext" v-if="swipList.length">
              <div
                :class="['before', crrentItem == 1 ? 'dis' : 'activ']"
                @click="lunboleft"
              >
                <span class="el-icon-arrow-left"></span>
              </div>
              <div class="nums">{{ crrentItem }}/{{ totoleNum }}</div>
              <div
                :class="['after', crrentItem == totoleNum ? 'dis' : 'activ']"
                @click="lunboright"
              >
                <span class="el-icon-arrow-right"></span>
              </div>
            </div>
          </div>
          <swiper
            class="swiper defistyle"
            :options="swiperOption"
            v-if="swipList.length"
          >
            <swiper-slide
              class="defiwidth"
              style=""
              v-for="(item, index) in swipList"
              :key="index"
            >
              <div @click="handleDetail(item.id, 'home')">
                <img
                  v-if="item.contentImgUrl"
                  :src="fixedPath + item.contentImgUrl"
                />
                <img v-else src="@/assets/newSite/news/no.png" alt="" />
                <div
                  style="
                    font-size: 14px;
                    color: #333333;
                    padding: 10px 10px 0 10px;
                    text-align: left;
                    font-weight: 700;
                  "
                >
                  {{ item.contentDatetime }}
                </div>
                <div
                  style="
                    text-align: left;
                    padding: 0.8rem;
                    font-size: 14px;
                    height: 120px;
                    width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 5;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    color: #333333;
                  "
                >
                  {{ item.contentDescription }}
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination">1</div>
            <div class="swiper-button-prev" slot="button-prev">2</div>
            <div class="swiper-button-next" slot="button-next">3</div>
          </swiper>
          <div style="padding-top: 20px; width: 100%" v-if="!swipList.length">
            暂无新闻数据...
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-news d-block d-lg-none" v-if="false">
      <div class="container text-center">
        <h3 class="pro-tit">新闻咨询</h3>

        <div class="controls">
          <div class="wrap">
            <div
              @click="handleSelectTpye(idxs)"
              v-for="(itm, idxs) in types"
              :key="idxs"
              :class="['opertaion', sel == idxs ? 'active' : '']"
            >
              <span>{{ itm }}</span>
            </div>
          </div>
        </div>

        <div class="news-swiper">
          <swiper
            class="swiper m_swiper"
            :options="swiperOption2"
            v-if="swipList.length"
          >
            <swiper-slide v-for="(item, index) in swipList" :key="index">
              <div @click="handleDetail(item.id, 'home')">
                <img
                  v-if="item.contentImgUrl"
                  :src="fixedPath + item.contentImgUrl"
                  alt=""
                />
                <img v-else src="@/assets/newSite/news/no.png" alt="" />
                <div
                  style="
                    font-size: 14px;
                    color: #333333;
                    padding: 10px 10px 0 10px;
                    text-align: left;
                  "
                >
                  {{ item.contentDatetime }}
                </div>
                <div
                  style="
                    text-align: left;
                    padding: 0.5rem;
                    font-size: 14px;
                    height: 90px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 4;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    color: #333333;
                  "
                >
                  {{ item.contentDescription }}
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination-m" slot="pagination"></div>
            <div class="swiper-button-prev-m" slot="button-prev"></div>
            <div class="swiper-button-next-m" slot="button-next"></div>
          </swiper>
          <div style="padding-top: 20px" v-if="!swipList.length">
            暂无新闻数据...
          </div>
          <div class="preAndNext" v-if="swipList.length">
            <div
              :class="['before', crrentItem == 1 ? 'dis' : 'activ']"
              @click="lunboleftM"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ crrentItem }}/{{ totoleNumMobile }}</div>
            <div
              :class="[
                'after',
                crrentItem == totoleNumMobile ? 'dis' : 'activ',
              ]"
              @click="lunborightM"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <NewFooter />

    <right-nav-new class="d-none d-lg-block" />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>

<script>
import {
  Companydynamics,
  Industrydynamics,
  Newsdetail,
  Dynamicnavigationbar,
  Newslist,
} from "@/api/fetch";

// import Banner from "@/components/Banner/index.vue";
import advSpace from "@/components/AdvertisingSpace.vue";
import countTo from "vue-count-to";
import Qs from "qs";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);
// import 'animate.css';
// import { WOW } from 'wow.js'

export default {
  name: "Home",
  data() {
    return {
      
      startVal: 0,
      endVal: 9,

      startCompanys: 0,
      endCompanys: 40,

      startUser: 0,
      endUser: 100,

      fixedPath: this.$BaseUrl,
      shows: true,
      show: false,

 
      dynamic: [],
      Journalism: [],
      tabs: ["华瀚管道", "数研时空", "润玉空间"],
      idx: 0,
      types: ["公司动态", "行业资讯"],
      sel: 0,
      totoleNum: 0,
      totoleNums: 0,
      totoleNumMobile: 0,
      swipList: [
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news2.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news2.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news2.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
      ],
      crrentItem: 1,

      swiperOptionBanner: {
        // effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        // spaceBetween: 30,
        // centeredSlides: false,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-w",
          clickable: true,
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },

      swiperOptionOffice: {
        // effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: false,
        spaceBetween: 0,
        centeredSlides: false,
        autoplay: {
          
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-top-off",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      swiperOptionSence: {
        // effect: "fade",
        fadeEffect: {
          // crossFade: true,
        },
        mousewheel: true, // 鼠标滚轮控制
        loop: true,
        spaceBetween:20,
        speed: 1500,
        centeredSlides: false,
        slidesPerView: 4,
        autoplay: {
          pauseOnMouseEnter:true,
          delay:3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-top",
          clickable: true,
        },
 
      },
      swiperOptionSenceMobile:{
        // effect: "fade",
        fadeEffect: {
          // crossFade: true,
        },
        mousewheel: true, // 鼠标滚轮控制
        loop: true,
        spaceBetween:30,
        speed: 1500,
        centeredSlides: false,
        slidesPerView: 1,
        autoplay: {
          pauseOnMouseEnter:true,
          delay:500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-top",
          clickable: true,
        },
      },
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 40,
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChange: (e) => {
            this.totoleNum =
              this.swipList.length - e.passedParams.slidesPerView + 1;
            this.crrentItem = e.activeIndex + 1;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      swiperOption2: {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        pagination: {
          el: ".swiper-pagination-m",
          clickable: true,
        },
        on: {
          slideChange: (e) => {
            this.totoleNumMobile =
              this.swipList.length - e.passedParams.slidesPerView + 1;
            this.crrentItem = e.activeIndex + 1;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next-m",
          prevEl: ".swiper-button-prev-m",
        },
      },

      swiperOptionsPc: {
        autoplay: {
          delay: 5000,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            let e = $(".banner-pc-nav").find("li")[this.activeIndex];
            $(e).siblings().removeClass("active");
            $(e).addClass("active");
          },
        },
      },
      swiperOptionsMobile: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        height: 100,
        speed: 600,
      },
 
      marginTop: 70,
      
      windowHeight: document.body.clientHeight,
      windowWidth: document.body.clientWidth + "px" || "1920px",
      hoverKey: -1,
      isMobile: false,
      ids: "1704670299637411842", //公司动态 //行业咨询 1704670418562707457
      baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
    };
  },
  components: {
    // Banner,
    countTo,
    advSpace,
    Swiper,
    SwiperSlide,
  },
  watch: {
    // 监听页面高度
    windowHeight(val) {
      console.log("实时屏幕高度：", val, this.windowHeight);
    },
    // 监听页面宽度
    windowWidth(val) {
      console.log("实时屏幕宽度：", val, this.windowHeight);
    },
  },
  created() {
    // this.getNews();
    this.getCase();
  },
  methods: {
    handleTry(){
     window.open('https://apipecloud.veiban.com/login','_blank')
    },
     disScroll(){
      document.body.classList.add('no-scroll')
     },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path});
      window.open(routeData.href, "_blank");
    },
    handleDetail(id, origin) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
          origin,
        },
      });
    },
    //成功案例
    getCase() {
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'home' })
      ).then((res) => {
        if (res.data.rows) {
          this.totoleNum = res.data.rows.length - this.swiperOption.slidesPerView + 1;
          this.totoleNumMobile = res.data.total;
          this.swipList = res.data.rows;
          if(res.data.rows.length){
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
          }
          this.caseList =  res.data.rows
        }
        // console.log(res, 999);
      });
    },
    //获取新闻
    getNews() {
      Newslist(
        //this.ids
        Qs.stringify({ categoryId: this.ids, pageSize: 10, pageNo: 1,productCode:'home' })
      ).then((res) => {
        if (res.data.rows) {
          this.totoleNum = res.data.rows.length - this.swiperOption.slidesPerView + 1;
          this.totoleNumMobile = res.data.total;
          console.log(this.totoleNum, 777);
          this.swipList = res.data.rows;
        }
        // console.log(res, 999);
      });
    },

    lunboleft() {
      // console.log(document,9999)
      document
        .querySelector(".swiper")
        .querySelector(".swiper-button-prev")
        .click();
      // console.log(123);
    },
    lunboright() {
      // console.log(document,9999)
      document
        .querySelector(".swiper")
        .querySelector(".swiper-button-next")
        .click();
      // console.log(123);
    },
    lunboleftM() {
      // console.log(document,9999)
      document
        .querySelector(".m_swiper")
        .querySelector(".swiper-button-prev-m")
        .click();
      console.log(456);
    },
    lunborightM() {
      console.log(
        document
          .querySelector(".m_swiper")
          .querySelector(".swiper-button-next-m"),
        111
      );
      document
        .querySelector(".m_swiper")
        .querySelector(".swiper-button-next-m")
        .click();
      console.log(789);
    },

    handleSelectTpye(val) {
      this.sel = val;
      if (val == 0) {
        this.ids = "1704670299637411842";
      } else {
        this.ids = "1704670418562707457";
      }
      this.getNews();
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    _isMobile() {
      // console.log(123456789);
      let flag =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      console.log(flag, 5555);
      return (this.isMobile = flag);
    },

    handleChange() {},
    handleMouseEnter(val) {
      this.hoverKey = val;
    },
    handleMouseLeave() {
      this.hoverKey = -1;
    },
    onindexs(val) {
      if (val == 1) {
        let id = "5";
        this.$router.push({
          path: `/currency/${id}`,
        });
      } else if (val == 2) {
        let id = "6";
        this.$router.push({
          path: `/Industry/${id}`,
        });
      }
    },
    onindex(val) {
      if (val == 1) {
        this.$router.push("/MES");
      } else if (val == 2) {
        this.$router.push("Download");
      } else if (val == 3) {
        this.$router.push("CRM");
      } else if (val == 4) {
        this.$router.push("ERP");
      }
    },
    getNewsList() {
      let data = {
        categoryId: "5",
        pageSize: 5,
      };
      let data2 = {
        categoryId: "6",
        pageSize: 5,
      };
      Companydynamics(Qs.stringify(data)).then((result) => {
        this.dynamic = result.data.rows;
      });
      Industrydynamics(Qs.stringify(data2)).then((result) => {
        this.Journalism = result.data.rows;
      });
    },
    // 点击新闻详情
    newsdetail(item) {
      let obj = {
        id: item.id,
        contentDisplay: "0",
      };
      Newsdetail(obj).then((res) => {
        let strItem = JSON.stringify(item);
        if (item.categoryId == "5") {
          this.$router.push(
            `/A2/` + encodeURIComponent(strItem).replace(/%/g, "%25")
          );
        }
        if (item.categoryId == "6") {
          this.$router.push(
            `/I1/` + encodeURIComponent(strItem).replace(/%/g, "%25")
          );
        }
      });
    },
    linkTo(name_) {
      this.$router.push({
        name: name_,
      });
    },
  },
};
</script>

<style lang="less">
.defistyle .defiwidth {
  width: 220px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.Mswiper {
  background-color: transparent;
  .swiper-pagination-bullets {
    position: absolute;
    z-index: 99;
    left: 240px;
    bottom: 100px;
    .swiper-pagination-bullet {
      background: whitesmoke;
      margin: 0 3px;
    }
    .swiper-pagination-bullet-active{
      background: white !important;
      width: 30px !important;
      border-radius: 6px !important;
      display: inline-block !important;
    }
  }
}

.MswiperMobile {
  .swiper-pagination-bullets {
    text-align: center !important;
    bottom: 50px !important;
    .swiper-pagination-bullet {
      background: #1484ff !important;
    }
    .swiper-pagination-bullet-active{
      background: white !important;
      width: 25px !important;
      border-radius: 6px !important;
      display: inline-block !important;
    }
  }
}
body {
  overflow-x: hidden ;

}

 

.pc-banner{

}
.mobile-banner{
  .swiper-pagination-w{
    position: absolute;
    bottom: 30px !important;
    z-index: 99;
    left: 40px;
    .swiper-pagination-bullet {
      background: white !important;
      margin: 0 3px;

    }
    .swiper-pagination-bullet-active{
      background: white !important;
      width: 30px !important;
      border-radius: 6px !important;
      display: inline-block !important;
    }
 
  }
}


.pc-future-provide {
  font-family: "思源黑体";
  .pro-tit {
    // font-size: 36px;
    margin: 80px 0 50px 0;
    color: #333;
  }
  .row{
    padding-left: 40px;
    height: 200px !important;
    background-image: url("../assets//newSite//home/data.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .items {
    display: flex;
    align-items: center;
    height: 100%;
    img {
      width: 10rem;
      height: 10rem;
    }
    .datas {
      color: #1484ff;
      font-weight: 700;
    }
    .tip {
      color: #333333;
      font-size:1.125rem; //18px
    }
  }
}
.mobile-future-provide {
  padding: 20px 0 80px 0;
  margin-top: 20px;
  font-family: "思源黑体";
  .pro-tit {
    color: #333;
    font-family:Source Han Sans CN;
    font-weight:700;
    color:#333333;
    font-size: 20px;
  }
  .pro-des{
    // margin-bottom: 20px;
    font-size: 13px;
    color: #666666;
  }
 
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .items {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    img {
      width: 10rem;
      height: 10rem;
    }
    .datas {
      color: #1484ff;
      font-weight: 700;
      font-size: 36px;
    }
    .tip {
      color: #333333;
      font-size: 1rem;
    }
  }
}



.mobile-soft-provide {
  padding: 50px 0 0 0;
  font-family: "思源黑体";
  height: 550px;
  .container {
    height: 100%;
    margin: 10px auto;
    width: 95%;
    background-image: url("../assets//newSite//banner/s4.png");
    height: 550px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    margin-top: -100px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
  }
  .row {
    border-radius: 5px;
  }
  .pro-tit {
    color: #333;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 700;
  }
  .pro-tits {
    color: #666666;
    font-size: 13px;
  }
  .wrap {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
  .items {
    display: flex;
    align-items: center;
    padding-inline: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    img {
      width: 8rem;
      height: 8rem;
    }
    .datas {
      color: #1484ff;
      font-weight: 700;
    }
    .tip {
      color: #333333;
      font-size: 1rem;
    }
  }
}

.pc-lunbo{
  .wrap{
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    width: 100%;
    padding:10px 0 10px 30px;
    // background: rgba(0, 0, 0, .3);
    .tit{
     font-size: 20px;
     text-align: left;
    }
    .pro{
   text-align: left;
    }
  }
 
}
.mobile-lunbo{
  .pro-tit{
    font-size: 20px ;
    font-weight: 700;
  }
  .wrap{
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    width: 100%;
    padding:10px 0 10px 30px;
    // background: rgba(0, 0, 0, .3);
    .tit{
     font-size: 1rem;
     text-align: left;
     font-weight:700;
    }
    .pro{
      text-align: left;
      font-size: 14px;
    }
  }
 
}

.pc-quanwei{
 .pro-tit{
  margin-bottom: 30px;
 }
padding: 20px 0;
.cot{
  display: flex;
  justify-content: space-around;
  .items{
    width: 128px;
    height: 128px;
     color: #333;
    div{
      margin-top: 20px;
    }
  }
}
}
.mobile-quanwei{
 .pro-tit{
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 700;
 }
padding: 40px 0;
.cot{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .items{
     width: 180px;
     color: #333;
     flex-shrink: 0;
     margin-bottom: 20px;
    div{
      margin-top: 20px;
    }
  }
}
}


.pc-parkner{
  background:#f6faff;
  padding: 70px 0;
  .pro-wrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-around;
    .item{
      width: 19%;
      height: 180px;
      margin: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.mobile-parkner{
  .pro-tit{
    font-size: 20px;
    font-weight: 700;
  }
  background:#f6faff;
  padding: 40px 0 0 0;
  margin-bottom: 40px;
  .pro-wrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    flex-shrink: 0;
    justify-content: space-around;
    .item{
      width: 160px;
      height: 106px;
      margin: 5px;
      img {
        width: 100%;
        
      }
    }
  }
}



.pc-number {
  .acticv {
    border-bottom: 2px solid rgba(20, 132, 255, 1);
  }
  .col {
    padding: 1rem 0;
    cursor: pointer;
  }
  .pro-wrap {
    background: #f6faff;
    text-align: left;
    .container {
      display: flex;
      .cot {
        background: url("../assets/newSite/home/pc.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 702px;
        width: 100%;
      }
      img {
        height: 20rem;
      }
      .content {
        margin: 40px 0;
        width: 100%;
        .mes {
          position: absolute;
          left: 15px;
          top: 150px;
        }
        .hrm {
          position: absolute;
          right: 15px;
          top: 170px;
        }
        .isc {
          position: absolute;
          left: 15px;
          top: 280px;
        }
        .FIMS {
          position: absolute;
          right: 15px;
          top: 280px;
        }
        .erp {
          position: absolute;
          left: 15px;
          top: 390px;
        }
        .plm {
          position: absolute;
          right: 15px;
          top: 410px;
        }
        .wms {
          position: absolute;
          left: 15px;
          top: 530px;
        }
        .crm {
          position: absolute;
          right: 15px;
          top: 550px;
        }
        .ibms {
          position: absolute;
          right: 41%;
          bottom: 40px;
        }

        .p-content {
          color: #666;
          font-size: 0.8rem;
          font-family: "思源黑体";
          line-height: 1.6rem;
          width: 500px;
        }

        .btns {
          margin: 1rem 0;
          button {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}
.mobile-number {
  .acticv {
    border-bottom: 2px solid rgba(20, 132, 255, 1);
  }
  .col {
    padding: 1rem 0;
    cursor: pointer;
  }
  .pro-wrap {
    background: #f6faff;
    text-align: left;
    .container {
      display: flex;
      position: relative;
      .cot {
        background: url("../assets/newSite/home/moblie.png");
        background-repeat: no-repeat;
        background-size: contain;
        z-index: inherit;
        height: 400px;
        width: 100%;
        scale: 1.3;
        background-color: #f6faff;

      }
  

      .pro-tit{
        font-size: 20px;
        font-family:Source Han Sans CN;
        font-weight:700;
        color:#333333;
      }
      .main{
        position: absolute;
            left: 0;
            bottom: 0;
            z-index: 99;
            width: 100%;
            background: #f6faff;
            padding-top: 40px;
            .des{
            font-size: 13px ;
            font-family:Source Han Sans CN;
            color:#666666;

          }        
      }

      .content {
        margin: 0 0;
        width: 100%;
 
 
      }
    }
  }
}

.pc-product {
  .acticv {
    // border-bottom: 2px solid rgba(20, 132, 255, 1);
    span{
    height:4px;
    border-bottom:4px solid #1484ff;
    color: #1484ff;
  }
  }
  .col {
    cursor: pointer;
    span{
    display: inline-block;
    height: 40px;
  }
  }
  .pro-wrap {
    background: #f6faff;
    text-align: left;
    .container {
      display: flex;
 
      .cot {
        background: url("../assets/newSite/home/pc.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 702px;
        width: 100%;
      }
      img {
        // height: 20rem;
      }
      .content {
        margin: 40px 0;
        width: 100%;
        .wrap{
          flex: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p{
            font-family:Source Han Sans CN;
            font-weight:200;
            color:#333333;
            width: 540px;
            margin-bottom: 20px;
            margin-top: 12px;
          }
        }
        .btn{
          width: 120px;
          background: #1484ff;
          height: 36px;
        }

      }
    }
  }
}
.mobile-product {
  .acticv {
    span{
    height:4px;
    border-bottom:4px solid #1484ff;
    color: #1484ff;
  }
  
  }
  .col {
    padding: 1rem 0;
    cursor: pointer;
    span{
    display: inline-block;
    height: 40px;
  }
  }
  .pro-tit {
    font-family:Source Han Sans CN;
    font-weight:700;
    color:#333333;
    font-size: 20px;
  }
  .pro-wrap {
    text-align: left;
    .container {
      display: flex;
      .content {
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        background: #f6faff;
        width: 100%;
        img {
          width: 100%;
        }
        .p-content {
          color: #666;
          font-size: 0.8rem;
          font-family: "思源黑体";
          line-height: 1.6rem;
        }
        .btn{
          width: 120px;
          background: #1484ff;
          height: 36px;
          color: white;
        }
 
      }
    }
  }
}

.pc-resolve {
  padding: 50px 0 20px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  .pro-tit {
    // font-size: 36px;
    color: #333;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .bg1 {
    height: 23rem;
    background: url("../assets/newSite/bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .bg2 {
    height: 23rem;
    background: url("../assets/newSite/home/a2.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .bg3 {
    height: 23rem;
    background: url("../assets/newSite/home/a1.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .row {
    display: flex;
    align-items: center;
    padding: 3rem;
    p {
      font-size: 1rem;
      color: #666666;
      font-family: "思源黑体";
      margin-top: 1.5rem;
    }
  }
}

.mobile-resolve {
  padding: 20px 0 20px 0;
  // margin-top: 1rem;
  font-family: "思源黑体";
  .pro-tit {
    // font-size: 36px;
    color: #333;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .bg1 {
    height: 12.5rem;
    background: url("../assets/newSite/home/b.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: right;
    .main-content {
      width: 35rem;
    }
  }
  .bg2 {
    height: 12.5rem;
    background: url("../assets/newSite/home/c.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .bg3 {
    height: 12.5rem;
    background: url("../assets/newSite/bs.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: right;
    .main-content {
      width: 35rem;
    }
  }
  .row {
    display: flex;
    align-items: center;
    padding: 2rem;
    p {
      font-size: 1rem;
      color: #666666;
      font-family: "思源黑体";
      margin-top: 1rem;
    }
  }
}

.pc-news {
  padding: 30px 0 60px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  background: #f5f6f7;
  .pro-tit {
    color: #333;
    margin-bottom: 20px;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }

  .news-swiper {
    display: flex;
    .controls {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .opertaion {
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        background: white;
        font-size: 1rem;
        font-family: "思源黑体";
        position: relative;
      }
      .active {
        background: rgba(224, 239, 255, 1);
        color: rgba(20, 132, 255, 1);
        position: relative;
        overflow: hidden;
        :after {
          position: absolute;
          content: "";
          left: 0;
          height: 70px;
          width: 3px;
          background: rgba(20, 132, 255, 1);
        }
      }
      .preAndNext {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }
      }
    }
    .swiper {
      width: 1000px;
      margin-left: 50px;
      .swiper-slide {
        background: white;
        &:hover {
          cursor: pointer;
        }
      }
      .bgArror {
        width: 30px;
        height: 10px;
        background-image: url("../assets/newSite/line.png");
        background-size: contain;
        margin-left: 10px;
        margin-top: 20px;
      }
      .swiper-pagination {
        display: none;
      }
      img {
        width: 100%;
        height: 150px;
        overflow: hidden;
      }
    }
    .swiper-container {
      height: 320px;
    }
  }

  .swiper-container .swiper-button-next {
    display: none;
  }

  .swiper-container .swiper-button-prev {
    display: none;
  }
}

.mobile-news {
  padding: 30px 0 20px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  background: #f5f6f7;
  .pro-tit {
    color: #333;
    margin-bottom: 20px;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .controls {
    .wrap {
      display: flex;
    }
    .opertaion {
      width: 100%;
      height: 70px;
      line-height: 70px;
      cursor: pointer;
      background: white;
      font-size: 1rem;
      font-family: "思源黑体";
      position: relative;
    }
    .active {
      background: rgba(224, 239, 255, 1);
      color: rgba(20, 132, 255, 1);
      position: relative;
      overflow: hidden;
      :after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 3px;
        width: 100%;
        background: rgba(20, 132, 255, 1);
      }
    }
  }

  .news-swiper {
    .m_swiper {
      // width: 900px;
      .swiper-slide {
        border: 1px solid #ccc;
      }
      img {
        width: 100%;
        height: 200px;
        overflow: hidden;
      }
    }
    .swiper-container {
      height: 300px;
    }

    .preAndNext {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      .before,
      .after {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #ccc;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
      .activ {
        border: 1px solid rgba(20, 132, 255, 1);
      }
      .dis {
        cursor: not-allowed !important;
      }
    }
  }

  .swiper-container .swiper-button-next {
    display: none;
  }

  .swiper-container .swiper-button-prev {
    display: none;
  }
}

.css_time {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.title {
  height: 48px;
  margin: 0 auto;
  font-size: 34px;
  letter-spacing: 3px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 600;
  text-align: center;
  color: #333333;
  line-height: 10px;
}
.TitleContent4 {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 18%;
  top: 36%;
}
.TitleContent2 {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 23%;
  top: 21%;
}

.Webanapp {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 72%;
  top: 21%;
}
.Efficientmanagement {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 18%;
  top: 36%;
}
.Efficientmanagement1 {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 71%;
  top: 21%;
  z-index: 9;
}

.Efficientmanagement4 {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 20.6%;
  top: 21%;
}
.css_button2 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 23%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.css_button3 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 71%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer;
  z-index: 99;
}
.css_button4 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 71%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer;
  z-index: 99;
}
.css_button5 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 22%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer;
}
.Weibanhelpyou {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 66%;
  top: 36%;
}
.TitleContent {
  position: absolute;
  left: 41%;
  top: 50%;
  font-size: 2vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #262c33;
}
.el-carousel__item--card {
  width: 34%;
}
.el-carousel__container {
  margin-left: 110px;
}
.TitleContent3 {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 66%;
  top: 36%;
  z-index: 9;
}
.css_button {
  font-size: 24px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 46%;
  top: 65%;
  border-radius: 25px;
  width: 164px;
  height: 66px;
  /* border: 1px solid red; */
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.css_button1 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 46%;
  top: 65%;
  border-radius: 25px;
  width: 144px;
  height: 48px;
  /* border: 1px solid red; */
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.css_homepage {
  position: relative;
}
.Clickthelink {
  display: flex;
  // border: 1px solid red;
  flex-direction: column;
  justify-content: space-between;
  // z-index: 9999;
  // height: 80px;
  // box-shadow: 10px 10px 5px #9521de;
}
.Clickthelink1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  /* bottom: 10%; */
  top: 50%;
  font-size: 1vw;
  color: white;
}

.css_shenglue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 500px;
  // border: 1px solid red;
}
.Clickthelinkcont {
  display: flex;
  justify-content: inherit;
  padding: 5px 20px;
  cursor: pointer; //鼠标悬浮变 "手";
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 620px;
  margin: 16px 0px;
}
.Clickthelinkcont1 {
  display: flex;
  // border: 1px solid red;
  justify-content: inherit;
  padding: 0px 0px;
  // box-shadow: 10px 10px 5px #9521de;
  cursor: pointer//鼠标悬浮变 手;
}
// .right_time{
//       margin-left: 223px;
// }
.css_imgcont2_title {
  position: absolute;
  top: 7%;
  left: 37%;
  display: flex;
}
.dynamic {
  color: white;
  font-size: 30px;
  margin-left: 10px;
  padding-top: 3px;
}
.Newsinformation {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 60px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.css_Picturecontainer {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // margin: 40px 0px;
}
.css_imgcont {
  display: flex;
  width: 49.4%;
  height: 18%;
  margin: 5px;
  overflow: hidden;
}
.css_imgcont2 {
  cursor: pointer;
  flex-direction: column;
  display: flex;
  width: 100%;
  // height: 18%;
  margin: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 18px 0px #daebff;
}
.showImg {
  width: 100%;
  height: 100%;
  transition: transform 2s;
}
.showImg:hover {
  transform: scale(1.2, 1.2);
}

.describe {
  // width: 1240px;
  // height: 84px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #666666;
  line-height: 24px;
  margin: 0px;
}
// @import "../styles/public";
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
}
.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
  margin-left: 22px;
}
.ellipsis2 {
  margin-right: 250px;
  font-size: 18px;
  margin-left: 22px;
}
.customer {
  text-align: left;
  margin: 20px 36px 31px 40px;
}
.img {
  padding-top: 10px;
}
.customer_img {
  margin-left: -17px;
}
.el-carousel__item:nth-child(2n) {
  background-color: white;
  height: 325px;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: white;
}
.customer_name {
  width: 50%;
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
  margin: 10px 0 20px;
}
.customer_introduce {
  width: 100%;
  height: 81px;
  opacity: 1;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #858d9a;
  line-height: 24px;
}
</style>
